import React from "react"


const Contact = () => {
    return (
        <>
            <h2>Reach Out!</h2>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            <p>I hope we get a chance to chat about current opportunities, or software engineering in general. Let's grow together.</p>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

        </>
    )
}

export default Contact